import React, { useState, useEffect, useCallback } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../firebaseConfig';
import { useNavigate } from 'react-router-dom';
import MultiStepFormLayout from './MultiStepFormLayout'; 
import axios from 'axios'; // Import axios for API calls


function ComplexSelection({ userId, userData, setUserData }) {
  const [complexes, setComplexes] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false); 
  const navigate = useNavigate();
  const { selectedStates, selectedDistricts, selectedComplexes, advocateName } = userData;
  const step = 4; // Correct step number
  const totalSteps = 7;
  const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;


  const loadComplexes = useCallback(async () => {
    const complexMap = {};

    try {
      for (const [state, districts] of Object.entries(selectedDistricts)) {
        for (const district of districts) {
          const snapshot = await getDocs(
            collection(db, 'districtstates', state, 'districts', district, 'complexes')
          );
          const districtComplexes = snapshot.docs.map(doc => doc.id);
          if (!complexMap[state]) {
            complexMap[state] = {};
          }
          complexMap[state][district] = districtComplexes;
        }
      }
      setComplexes(complexMap);
    } catch (error) {
      console.error('Error fetching complexes:', error);
      setErrorMessage('Failed to load complexes.');
    } finally {
      setIsLoading(false);
    }
  }, [selectedDistricts]);

  useEffect(() => {
    if (selectedDistricts && Object.keys(selectedDistricts).length > 0) {
      loadComplexes();
    }
  }, [selectedDistricts, loadComplexes]);

  const toggleComplexSelection = (state, district, complex) => {
    const newSelectedComplexes = { ...userData.selectedComplexes };

    if (!newSelectedComplexes[state]) {
      newSelectedComplexes[state] = {};
    }

    if (!newSelectedComplexes[state][district]) {
      newSelectedComplexes[state][district] = [];
    }

    if (newSelectedComplexes[state][district].includes(complex)) {
      newSelectedComplexes[state][district] = newSelectedComplexes[state][district].filter(c => c !== complex);
    } else {
      newSelectedComplexes[state][district].push(complex);
    }

    setUserData({ ...userData, selectedComplexes: newSelectedComplexes });
  };

  const triggerApiCalls = async () => {
    setIsSubmitting(true);

    const apiCalls = [];
    for (const [state, districts] of Object.entries(selectedComplexes)) {
      for (const [district, complexes] of Object.entries(districts)) {
        for (const complex of complexes) {
          apiCalls.push({ stateName: state, districtName: district, courtComplexName: complex });
        }
      }
    }

    for (let i = 0; i < apiCalls.length; i++) {
      const { stateName, districtName, courtComplexName } = apiCalls[i];
      try {
        console.log(`Triggering API call for: ${stateName} > ${districtName} > ${courtComplexName}`);
        await axios.post(`${API_BASE_URL}/district_advo`, {
          stateName,
          districtName,
          courtComplexName,
          advocateName,
          userId,
        });
        console.log(`API call succeeded for: ${stateName} > ${districtName} > ${courtComplexName}`);
      } catch (error) {
        console.error(`API call failed for: ${stateName} > ${districtName} > ${courtComplexName}`, error);
      }

      // Add delay of 3 seconds between calls
      if (i < apiCalls.length - 1) {
        await new Promise(resolve => setTimeout(resolve, 3000));
      }
    }

    setIsSubmitting(false);
  };

  const handleNext = () => {
    if (isSubmitting) return; // Prevent duplicate submissions
  
    // Trigger API calls but don't await them
    triggerApiCalls();
  
    // Navigate to the next page immediately
    navigate('/highcourt-selection', { state: { userId, userData } });
  };
  

  const handleBack = () => {
    navigate(-1); // Go to the previous page
  };

  return (
    <MultiStepFormLayout
      step={step}
      totalSteps={totalSteps}
      onNext={handleNext}
      onBack={handleBack}
      isNextDisabled={Object.keys(userData.selectedComplexes).length === 0}
      isLoading={isLoading || isSubmitting}
      errorMessage={errorMessage}
    >
      {isSubmitting && <p className="loader">Submitting data, please wait...</p>} {/* Loader */}
      {selectedStates.map(state => (
        <div key={state}>
          {selectedDistricts[state]?.map(district => (
            <div key={district}>
              <h3>{district}, {state}</h3>
              <ul style={{ listStyleType: 'none', paddingLeft: 0 }}>
                {complexes[state]?.[district]?.map(complex => (
                  <li key={complex} style={{ display: 'flex', justifyContent: 'space-between', padding: '8px 0' }}>
                    <span>{complex}</span>
                    <input
                      type="checkbox"
                      checked={userData.selectedComplexes[state]?.[district]?.includes(complex) || false}
                      onChange={() => toggleComplexSelection(state, district, complex)}
                    />
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
      ))}
    </MultiStepFormLayout>
  );
}  

export default ComplexSelection;
