import React, { useState } from 'react';
import { TextField, MenuItem, Button, Box, Typography, Alert, Tabs, Tab } from '@mui/material';
import { auth } from '../firebaseConfig';
import axios from 'axios';
import { collection, addDoc } from 'firebase/firestore'; // Import addDoc instead of setDoc
import { db } from '../firebaseConfig'; // Make sure to import auth


const AddCasePage = () => {
  const [tabValue, setTabValue] = useState(0); // 0 for CNR-based, 1 for Manual Entry
  const [cnrNumber, setCnrNumber] = useState('');
  const [courtType, setCourtType] = useState('DistrictCourt');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Manual Entry Fields
  const [manualCaseData, setManualCaseData] = useState({
    court: '',
    petitioner: '',
    respondent: '',
    petitionerAdvocate: '',
    respondentAdvocate: '',
    nextHearingDate: '',
    caseStage: '',
  });

  const courtTypes = ['DistrictCourt', 'HighCourt'];

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    setShowSuccessMessage(false); // Clear success message on tab switch
  };

  const handleAddCNRCase = async () => {
    const userId = auth.currentUser?.uid;
  
    if (!userId) {
      alert('User is not logged in.');
      return;
    }
  
    // Trim whitespace and validate CNR number (must be exactly 16 alphanumeric characters)
    const trimmedCnr = cnrNumber.trim();
    if (!/^[A-Za-z0-9]{16}$/.test(trimmedCnr)) {
      alert('Please enter a valid CNR Number (16 alphanumeric characters).');
      return;
    }
  
    const endpoint =
      courtType === 'HighCourt'
        ? 'https://genlawyers.com/api/high_court_cnr'
        : 'https://genlawyers.com/api/district_cnr';
  
    setIsLoading(true);
    try {
      const response = await axios.post(endpoint, {
        userId,
        cnr: trimmedCnr,
      });
  
      if (response.data.success) {
        setShowSuccessMessage(true);
        setCnrNumber('');
      } else {
        alert(response.data.message || 'Error adding case, please try again.');
      }
    } catch (error) {
      console.error('Error adding case:', error);
      alert('Error adding case, please check your connection.');
    } finally {
      setIsLoading(false);
    }
  };
  

  const handleAddManualCase = async () => {
    const userId = auth.currentUser?.uid;
  
    if (!userId) {
      alert('User is not logged in.');
      return;
    }
  
    const { court, petitioner, respondent, petitionerAdvocate, respondentAdvocate, nextHearingDate, caseStage } = manualCaseData;
  
    if (!court || !petitioner || !respondent || !nextHearingDate) {
      alert('Please fill all required fields.');
      return;
    }
  
    const caseData = {
      court,
      petitioner,
      respondent,
      petitionerAdvocate,
      respondentAdvocate,
      nextHearingDate,
      caseStage,
      createdAt: new Date().toISOString(),
    };
  
    setIsLoading(true);
    try {
      const caseCollectionRef = collection(db, 'users', userId, 'CaseDetails'); // Reference to the CaseDetails collection
      const docRef = await addDoc(caseCollectionRef, caseData); // Automatically generate document ID
  
      console.log('Document added with ID:', docRef.id);
  
      setShowSuccessMessage(true);
  
      // Reset form fields after successful submission
      setManualCaseData({
        ownCNRNumber: '', // Clear this field since it's no longer relevant
        court: '',
        petitioner: '',
        respondent: '',
        petitionerAdvocate: '',
        respondentAdvocate: '',
        nextHearingDate: '',
        caseStage: '',
      });
    } catch (error) {
      console.error('Error adding manual case:', error);
      alert('Error adding manual case, please check your connection.');
    } finally {
      setIsLoading(false);
    }
  };

  const handleManualInputChange = (field, value) => {
    setManualCaseData((prev) => ({ ...prev, [field]: value }));
  };

  return (
    <Box sx={{ width: '100%', maxWidth: 600, mx: 'auto', mt: 5 }}>
      <Typography variant="h4" gutterBottom>
        Add New Case
      </Typography>

      {/* Tabs for CNR and Manual Entry */}
      <Tabs value={tabValue} onChange={handleTabChange} sx={{ mb: 3 }}>
        <Tab label="Add via CNR" />
        <Tab label="Manual Entry" />
      </Tabs>

      {/* CNR-Based Case Addition */}
      {tabValue === 0 && (
        <>
          <Typography variant="body1" gutterBottom>
            Enter CNR Number:
          </Typography>
          <TextField
            fullWidth
            value={cnrNumber}
            onChange={(e) => setCnrNumber(e.target.value)}
            label="CNR Number"
            variant="outlined"
            margin="normal"
          />

          <Typography variant="body1" gutterBottom>
            Select Court Type:
          </Typography>
          <TextField
            select
            fullWidth
            value={courtType}
            onChange={(e) => setCourtType(e.target.value)}
            label="Court Type"
            variant="outlined"
            margin="normal"
          >
            {courtTypes.map((type) => (
              <MenuItem key={type} value={type}>
                {type}
              </MenuItem>
            ))}
          </TextField>

          <Button
            variant="contained"
            color="primary"
            onClick={handleAddCNRCase}
            sx={{ mt: 2 }}
            disabled={isLoading}
          >
            {isLoading ? 'Adding Case...' : 'Add Case'}
          </Button>
        </>
      )}

      {/* Manual Case Entry */}
      {tabValue === 1 && (
        <>
          <TextField
            fullWidth
            label="Court"
            value={manualCaseData.court}
            onChange={(e) => handleManualInputChange('court', e.target.value)}
            variant="outlined"
            margin="normal"
          />
          <TextField
            fullWidth
            label="Petitioner"
            value={manualCaseData.petitioner}
            onChange={(e) => handleManualInputChange('petitioner', e.target.value)}
            variant="outlined"
            margin="normal"
          />
          <TextField
            fullWidth
            label="Respondent"
            value={manualCaseData.respondent}
            onChange={(e) => handleManualInputChange('respondent', e.target.value)}
            variant="outlined"
            margin="normal"
          />
          <TextField
            fullWidth
            label="Petitioner Advocate"
            value={manualCaseData.petitionerAdvocate}
            onChange={(e) => handleManualInputChange('petitionerAdvocate', e.target.value)}
            variant="outlined"
            margin="normal"
          />
          <TextField
            fullWidth
            label="Respondent Advocate"
            value={manualCaseData.respondentAdvocate}
            onChange={(e) => handleManualInputChange('respondentAdvocate', e.target.value)}
            variant="outlined"
            margin="normal"
          />
          <TextField
            fullWidth
            type="date"
            label="Next Hearing Date"
            value={manualCaseData.nextHearingDate}
            onChange={(e) => handleManualInputChange('nextHearingDate', e.target.value)}
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            margin="normal"
          />
          <TextField
            fullWidth
            label="Case Stage"
            value={manualCaseData.caseStage}
            onChange={(e) => handleManualInputChange('caseStage', e.target.value)}
            variant="outlined"
            margin="normal"
          />

          <Button
            variant="contained"
            color="primary"
            onClick={handleAddManualCase}
            sx={{ mt: 2 }}
            disabled={isLoading}
          >
            {isLoading ? 'Adding Case...' : 'Add Case'}
          </Button>
        </>
      )}

      {showSuccessMessage && (
        <Alert severity="success" sx={{ mt: 2 }}>
          Case added successfully! It will appear in a few minutes.
        </Alert>
      )}
    </Box>
  );
};

export default AddCasePage;
