import React, { useState, useEffect } from 'react';
import { doc, getDoc, updateDoc, collection, getDocs, query, where } from 'firebase/firestore';
import { db, auth } from '../firebaseConfig'; // Import your Firestore config and Auth
import './Profile.css'; // Add a CSS file for styling if necessary

const Profile = () => {
  const userId = auth.currentUser ? auth.currentUser.uid : null; // Get the user ID from Firebase Auth

  const [advocateData, setAdvocateData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [caseCounts, setCaseCounts] = useState({ district: 0, highCourt: 0, cat: 0 });
  const [bankDetails, setBankDetails] = useState({
    accountNumber: '',
    ifscCode: '',
    upiId: ''
  });

  useEffect(() => {
    const fetchAdvocateData = async () => {
      if (!userId) {
        console.error('No user is logged in!');
        setLoading(false);
        return;
      }

      try {
        const advocateDoc = doc(db, 'users', userId);
        const docSnapshot = await getDoc(advocateDoc);

        if (docSnapshot.exists()) {
          const data = docSnapshot.data();
          setAdvocateData(data);
          // Set bank details if they exist
          if (data.bankDetails) {
            setBankDetails(data.bankDetails);
          }
        } else {
          console.error('No such document!');
        }
      } catch (error) {
        console.error('Error fetching advocate data:', error);
      } finally {
        setLoading(false);
      }
    };

    const fetchCaseCounts = async () => {
      if (!userId) return;

      try {
        const caseDetailsRef = collection(db, 'users', userId, 'CaseDetails');

        // Query for District Court cases
        const districtQuery = query(caseDetailsRef, where('caseType', '==', 'DistrictCourt'));
        const districtSnapshot = await getDocs(districtQuery);
        const districtCount = districtSnapshot.size;

        // Query for High Court cases
        const highCourtQuery = query(caseDetailsRef, where('caseType', '==', 'HighCourt'));
        const highCourtSnapshot = await getDocs(highCourtQuery);
        const highCourtCount = highCourtSnapshot.size;

        // Query for CAT cases
        const catQuery = query(caseDetailsRef, where('caseType', '==', 'CAT'));
        const catSnapshot = await getDocs(catQuery);
        const catCount = catSnapshot.size;

        setCaseCounts({ district: districtCount, highCourt: highCourtCount, cat: catCount });
      } catch (error) {
        console.error('Error fetching case counts:', error);
      }
    };

    fetchAdvocateData();
    fetchCaseCounts(); // Call to fetch case counts
  }, [userId]); // Add userId as a dependency

  const handleBankDetailChange = (e) => {
    const { name, value } = e.target;
    setBankDetails({ ...bankDetails, [name]: value });
  };

  const handleSaveBankDetails = async () => {
    if (!userId) {
      console.error('No user is logged in!');
      return;
    }

    try {
      const advocateDocRef = doc(db, 'users', userId);
      await updateDoc(advocateDocRef, {
        bankDetails: {
          accountNumber: bankDetails.accountNumber,
          ifscCode: bankDetails.ifscCode,
          upiId: bankDetails.upiId,
        },
      });
      alert('Bank details saved successfully!');
    } catch (error) {
      console.error('Error saving bank details:', error);
      alert('Error saving bank details.');
    }
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div className="profile-container">
      <h2 className="profile-title">Advocate Profile</h2>

      {advocateData ? (
        <>
          <div className="profile-section">
            <h3>Personal Information</h3>
            <p><strong>Name:</strong> {advocateData.advocateName || 'N/A'}</p>
            <p><strong>Email:</strong> {advocateData.email || 'N/A'}</p>
            <p><strong>Mobile:</strong> {advocateData.mobile || 'N/A'}</p>
            <p><strong>License Number:</strong> {advocateData.licenseNumber || 'N/A'}</p>
          </div>

          <div className="profile-section">
            <h3>Case Summary</h3>
            <div className="case-summary">
              <div className="case-item">
                <h4>District Court</h4>
                <p>{caseCounts.district}</p>
              </div>
              <div className="case-item">
                <h4>High Court</h4>
                <p>{caseCounts.highCourt}</p>
              </div>
              <div className="case-item">
                <h4>CAT</h4>
                <p>{caseCounts.cat}</p>
              </div>
            </div>
          </div>

          <div className="profile-section">
            <h3>Bank Details</h3>
            {bankDetails.accountNumber ? (
              <div>
                <p><strong>Account Number:</strong> {bankDetails.accountNumber}</p>
                <p><strong>IFSC Code:</strong> {bankDetails.ifscCode}</p>
                <p><strong>UPI ID:</strong> {bankDetails.upiId}</p>
              </div>
            ) : (
              <div>
                <p>No bank details available. Please add your bank details below:</p>
                <div className="bank-details-form">
                  <label>
                    Account Number:
                    <input
                      type="text"
                      name="accountNumber"
                      value={bankDetails.accountNumber}
                      onChange={handleBankDetailChange}
                      placeholder="Enter account number"
                    />
                  </label>
                  <label>
                    IFSC Code:
                    <input
                      type="text"
                      name="ifscCode"
                      value={bankDetails.ifscCode}
                      onChange={handleBankDetailChange}
                      placeholder="Enter IFSC code"
                    />
                  </label>
                  <label>
                    UPI ID:
                    <input
                      type="text"
                      name="upiId"
                      value={bankDetails.upiId}
                      onChange={handleBankDetailChange}
                      placeholder="Enter UPI ID"
                    />
                  </label>
                </div>
                <button className="save-button" onClick={handleSaveBankDetails}>Save Bank Details</button>
              </div>
            )}
          </div>
        </>
      ) : (
        <p>No advocate data available.</p>
      )}
    </div>
  );
};

export default Profile;
