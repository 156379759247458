import React, { useState, useEffect } from 'react';
import { Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Modal, TextField, Box, Typography } from '@mui/material';
import './CaseDetail.css';
import { doc, updateDoc, addDoc, deleteDoc, arrayUnion, arrayRemove, collection, getDocs, getDoc, onSnapshot } from 'firebase/firestore'; // Import Firestore functions
import { db, storage, auth } from '../firebaseConfig'; // Import Firebase config and Storage
import { generateInvoicePDF } from './generateInvoicePdf';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Firebase storage functions
import { v4 as uuidv4 } from 'uuid';

function CaseDetail({ caseData }) {
    const [invoiceData, setInvoiceData] = useState({ amount: '', description: '', date: new Date().toISOString().substr(0, 10), });
    const [paymentData, setPaymentData] = useState({ amount: '', description: '', date: new Date().toISOString().substr(0, 10), });
    const [noteData, setNoteData] = useState({ content: '', date: new Date().toISOString().substr(0, 10), });
    const [todoData, setTodoData] = useState({
      description: '',
      date: new Date().toISOString().substr(0, 10), // Default to today's date
      assignedTo: '',
      isCompleted: false,
    });
    const [isFeeModalOpen, setIsFeeModalOpen] = useState(false);
    const [isInvoiceModalOpen, setIsInvoiceModalOpen] = useState(false);
    const [isNoteModalOpen, setIsNoteModalOpen] = useState(false);
    const [isTodoModalOpen, setIsTodoModalOpen] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [file, setFile] = useState(null);
    const [uploadedFiles, setUploadedFiles] = useState(caseData.uploadedFiles || []);
    const { petitionerAdvocate, respondentAdvocate, history, orders } = caseData;
    const [teamMembers, setTeamMembers] = useState([]); // Store team members
    const [caseDataState, setCaseData] = useState(caseData); // Define setCaseData state updater function
    const [caseDetail, setCaseDetail] = useState(caseData || {});
    const userId = auth.currentUser ? auth.currentUser.uid : null;
    const [payments, setPayments] = useState([]);
    const [invoices, setInvoices] = useState([]);
    const [tasks, setTasks] = useState([]);
    const [notes, setNotes] = useState([]);

    

    // State to toggle visibility of case status, history, and orders sections
    const [isCaseStatusExpanded, setIsCaseStatusExpanded] = useState(false);
    const [isHistoryExpanded, setIsHistoryExpanded] = useState(false);
    const [isOrdersExpanded, setIsOrdersExpanded] = useState(false);
  
    const handleToggleCaseStatus = () => setIsCaseStatusExpanded(!isCaseStatusExpanded);
    const handleToggleHistory = () => setIsHistoryExpanded(!isHistoryExpanded);
    const handleToggleOrders = () => setIsOrdersExpanded(!isOrdersExpanded);
  
    useEffect(() => {
      const fetchTeamMembers = async () => {
        if (!userId) {
          console.error("User ID is null or undefined.");
          return;
        }
    
        try {
          const teamCollectionRef = collection(db, 'users', userId, 'team');
          const teamSnapshot = await getDocs(teamCollectionRef);
          const members = teamSnapshot.docs.map(doc => doc.data());
          setTeamMembers(members);
        } catch (error) {
          console.error("Error fetching team members:", error);
        }
      };
    
      fetchTeamMembers();
    }, [userId]);
    
        // File upload handling
    const handleFileChange = (e) => {
      setFile(e.target.files[0]);
    };

    const handleFileUpload = async () => {
      if (!file) {
          alert("Please select a file to upload.");
          return;
      }

      setIsUploading(true);

      try {
          // Use userId in the storage path to comply with Firebase rules
          const storageRef = ref(storage, `caseFiles/${userId}/${caseData.id}/${file.name}`);
          await uploadBytes(storageRef, file);
          const fileURL = await getDownloadURL(storageRef);

          const caseDocRef = doc(db, 'users', userId, 'CaseDetails', caseData.id);
          const newFile = {
              name: file.name,
              url: fileURL,
              uploadedAt: new Date(),
          };

          // Update Firestore with the uploaded file details
          await updateDoc(caseDocRef, {
              uploadedFiles: arrayUnion(newFile),
          });

          // Update the state to reflect the new uploaded file
          setUploadedFiles((prevFiles) => [...prevFiles, newFile]);

          alert("File uploaded successfully!");
          setFile(null); // Reset file input after upload
      } catch (error) {
          console.error("Error uploading file:", error);
          alert("Error uploading file.");
      } finally {
          setIsUploading(false);
      }
    };


    const handleFeeReceived = async () => {
      if (!paymentData.amount || !paymentData.description || !paymentData.date) {
        alert("Please fill all the fields for Fee Received.");
        return;
      }
    
      const payment = {
        id: uuidv4(), // Generate a unique ID for the payment
        amount: paymentData.amount,
        description: paymentData.description.trim(),
        date: new Date(paymentData.date), // Ensure date is stored as a Firestore Timestamp
      };
    
      try {
        // Optimistically update the local state
        setPayments((prevPayments) => [...prevPayments, payment]);
    
        // Reference to the Payments subcollection
        const paymentsCollectionRef = collection(
          db,
          'users',
          userId,
          'CaseDetails',
          caseData.id,
          'Payments'
        );
    
        // Add the new payment document to Firestore
        await addDoc(paymentsCollectionRef, payment);
    
        console.log("Payment added successfully to Firestore:", payment); // Debugging: Log added payment
    
        alert("Fee received successfully.");
      } catch (error) {
        console.error("Error adding fee received:", error);
    
        // Rollback the local state in case of an error
        setPayments((prevPayments) => prevPayments.filter((p) => p.id !== payment.id));
    
        alert("Error adding fee received.");
      } finally {
        // Reset the form and close the modal
        setPaymentData({ amount: '', description: '', date: new Date().toISOString().substr(0, 10) });
        setIsFeeModalOpen(false);
      }
    };
    
  
    
    const handleGenerateInvoice = async () => {
      if (!invoiceData.amount || !invoiceData.description || !invoiceData.dueDate) {
        alert("Please fill all the fields for Invoice Generation.");
        return;
      }
    
      // Ensure petitioner and respondent advocates are defined as arrays
      const petitionerAdvocate = Array.isArray(caseDetail.petitionerAdvocate)
        ? caseDetail.petitionerAdvocate.join(", ")
        : caseDetail.petitionerAdvocate || "Unknown Petitioner";
    
      const respondentAdvocate = Array.isArray(caseDetail.respondentAdvocate)
        ? caseDetail.respondentAdvocate.join(", ")
        : caseDetail.respondentAdvocate || "Unknown Respondent";
    
      // Construct the invoice object with the required fields
      const invoice = {
        id: uuidv4(), // Generate a unique ID for the invoice
        amount: Number(invoiceData.amount), // Ensure amount is a number
        description: invoiceData.description.trim(), // Trim description to remove extra spaces
        date: new Date(invoiceData.date || new Date()), // Use selected date or today's date
        dueDate: new Date(invoiceData.dueDate), // Convert to Firestore-compatible Timestamp
        isPaid: false, // Default value for isPaid
      };
    
      try {
        // Optimistically update the local state
        setInvoices((prevInvoices) => [...prevInvoices, invoice]);
    
        // Reference to the Invoices subcollection
        const invoicesCollectionRef = collection(
          db,
          'users',
          userId,
          'CaseDetails',
          caseData.id,
          'Invoices'
        );
    
        // Add the new invoice document
        await addDoc(invoicesCollectionRef, invoice);
    
        console.log("Invoice saved to Firestore:", invoice);
    
        // Generate and open the invoice PDF
        await generateInvoicePDF(
          invoice,
          userId,
          petitionerAdvocate, // Pass formatted petitioner advocate
          respondentAdvocate   // Pass formatted respondent advocate
        );
    
        alert("Invoice saved to Firestore successfully.");
      } catch (error) {
        console.error("Error generating invoice:", error);
    
        // Rollback the local state in case of an error
        setInvoices((prevInvoices) => prevInvoices.filter((inv) => inv.id !== invoice.id));
    
        alert("Error generating invoice.");
      } finally {
        // Reset the form and close the modal
        setInvoiceData({ amount: '', description: '', date: '', dueDate: '' });
        setIsInvoiceModalOpen(false);
      }
    };
    

const handleAddNote = async () => {
  if (!noteData.content || !noteData.date || !noteData.type) {
    alert("Please fill all the fields for the Note.");
    return;
  }

  const note = {
    id: uuidv4(), // Generate unique ID
    content: noteData.content.trim(),
    date: new Date(noteData.date), // Convert to Firestore-compatible Timestamp
    type: noteData.type.trim(),
  };

  try {
    const notesCollectionRef = collection(
      db,
      'users',
      userId,
      'CaseDetails',
      caseData.id,
      'Notes'
    );

    // Optimistically update the local state
    setNotes((prevNotes) => [...prevNotes, note]);

    // Add the note to Firestore
    await addDoc(notesCollectionRef, note);

    alert("Note added successfully.");
  } catch (error) {
    console.error("Error adding note:", error);

    // Rollback in case of an error
    setNotes((prevNotes) => prevNotes.filter((n) => n.id !== note.id));
    alert("Error adding note.");
  } finally {
    // Reset form fields and close the modal
    setNoteData({ content: '', date: new Date().toISOString().substr(0, 10), type: '' });
    setIsNoteModalOpen(false);
  }
};


const handleAddTodo = async () => {
  if (!todoData.description || !todoData.date || !todoData.assignedTo) {
    alert("Please fill all the fields for the To-Do.");
    return;
  }

  try {
    const tasksCollectionRef = collection(db, 'users', userId, 'CaseDetails', caseData.id, 'Tasks');
    const newTodoRef = await addDoc(tasksCollectionRef, {
      taskId: uuidv4(), // Generate and include taskId at creation
      description: todoData.description.trim(),
      date: new Date(todoData.date),
      assignedTo: todoData.assignedTo,
      isCompleted: false,
    });

    // Fetch the new taskId (if needed for other purposes)
    const newTaskId = newTodoRef.id;

    // Optimistically update local state
    setTasks((prevTasks) => [
      ...prevTasks,
      {
        taskId: newTaskId,
        description: todoData.description.trim(),
        date: new Date(todoData.date),
        assignedTo: todoData.assignedTo,
        isCompleted: false,
      },
    ]);

    alert("To-Do added successfully.");
    setTodoData({ description: '', date: '', assignedTo: '', isCompleted: false });
    setIsTodoModalOpen(false);
  } catch (error) {
    console.error("Error adding To-Do:", error);
    alert("Error adding To-Do.");
  }
};


const handleCompleteTodo = async (todo) => {
  if (!todo.taskId) {
    console.error("To-Do taskId is missing.");
    alert("Error: Unable to mark To-Do as complete. Please refresh and try again.");
    return;
  }

  try {
    const taskDocRef = doc(db, 'users', userId, 'CaseDetails', caseData.id, 'Tasks', todo.taskId);

    await updateDoc(taskDocRef, { isCompleted: true });

    // Optimistically update local state
    setTasks((prevTasks) =>
      prevTasks.map((task) =>
        task.taskId === todo.taskId ? { ...task, isCompleted: true } : task
      )
    );

    alert("To-Do marked as completed successfully.");
  } catch (error) {
    console.error("Error marking To-Do as complete:", error);
    alert("Error completing To-Do. Please try again.");
  }
};

const handleInvoicePaid = async (invoice) => {
  const confirmPayment = window.confirm("Are you sure you want to mark this invoice as paid?");
  if (!confirmPayment) return;

  try {
    // Reference to the specific invoice document in the Invoices subcollection
    const invoiceDocRef = doc(db, 'users', userId, 'CaseDetails', caseData.id, 'Invoices', invoice.id);

    // Add the invoice to the Payments subcollection with the current date
    const paymentsCollectionRef = collection(db, 'users', userId, 'CaseDetails', caseData.id, 'Payments');
    const paymentData = {
      id: invoice.id, // Retain the invoice ID for reference
      amount: invoice.amount,
      description: invoice.description.trim(),
      date: new Date(), // Use the current date as the payment date
    };

    // Add the payment document to the Payments subcollection
    await addDoc(paymentsCollectionRef, paymentData);

    // Delete the invoice from the Invoices subcollection
    await deleteDoc(invoiceDocRef);

    // Optimistically update the local state
    setInvoices((prevInvoices) =>
      prevInvoices.filter((inv) => inv.id !== invoice.id)
    );

    setPayments((prevPayments) => [...prevPayments, paymentData]);

    alert("Invoice marked as paid and moved to Payments successfully.");
  } catch (error) {
    console.error("Error marking invoice as paid:", error);
    alert("Error marking invoice as paid. Please try again.");
  }
};

    
const parseCaseData = (caseData, payments = [], invoices = [], tasks = [], notes = []) => {
  switch (caseData.caseType) {
        case 'HighCourt': {
          const petitionerAdvocate = caseData.petitioner_advocate || "Unknown Petitioner";
          const respondentAdvocate = caseData.respondent_advocate || "Unknown Respondent";
          const nextHearingDate = caseData.next_hearing_date || "No Next Hearing Date";
          const courtDetails = caseData.case_details || {};
          return {
            ...caseData,
            payments, // Include fetched payments
            invoices, // Include fetched invoices
            tasks, // Include fetched tasks
            notes, // Include fetched notes
            petitionerAdvocate,
            respondentAdvocate,
            nextHearingDate,
            courtNumber: courtDetails["Registration Number"] || "Unknown Court",
            caseDetails: { // Explicitly map courtDetails to caseDetails
              cnrNumber: courtDetails["CNR Number"] || "Unknown CNR",
              registrationNumber: courtDetails["Registration Number"] || "Unknown Registration Number",
              filingNumber: courtDetails["Filing Number"] || "Unknown Filing Number",
            },
          };
        }
        
        case 'DistrictCourt': {
          const caseDetails = caseData.caseDetails || {};
          const caseStatus = caseData.caseStatus || {}; // Ensure caseStatus is included
          return {
            ...caseData,
            payments, // Include fetched payments
            invoices, // Include fetched invoices
            tasks, // Include fetched tasks
            notes, // Include fetched notes
            petitionerAdvocate: caseData.petitioner || "Unknown Petitioner",
            respondentAdvocate: caseData.respondent || "Unknown Respondent",
            nextHearingDate: caseData.nextHearingDate || "No Next Hearing Date",
            courtNumber: caseStatus["Court Number and Judge"] || "Unknown Court",
            caseStage: caseStatus["Case Stage"] || "N/A",
            caseDetails: {
              cnrNumber: caseDetails["CNR Number"] || "Unknown CNR",
              caseType: caseDetails["Case Type"] || "Unknown Case Type",
              filingNumber: caseDetails["Filing Number"] || "Unknown Filing Number",
              registrationNumber: caseDetails["Registration Number"] || "Unknown Registration Number",
            },
            caseStatus, // Include full caseStatus for use in JSX
            caseHistory: caseData.caseHistory || [],
            chatGptSuggestions: caseData.ChatGPTSuggestions || "No suggestions available",
            acts: caseData.acts || [],
          };
        }
    
        case 'CAT': {
          const applicantRespondent = caseData.applicantRespondent || "Unknown Title";
          const catNextDate = caseData.nextHearing || "No Next Hearing Date";
          const catCourt = caseData.courtNumber === "N/A" ? "Central Administrative Tribunal" : caseData.courtNumber;
          const catCaseNumber = caseData.caseNumber || "Unknown Case Number";
          const catStatus = caseData.status || "Unknown Status";
          return {
            ...caseData,
            title: applicantRespondent,
            nextHearingDate: catNextDate,
            court: catCourt,
            caseNumber: catCaseNumber,
            status: catStatus,
            payments, // Include fetched payments
            invoices, // Include fetched invoices
            tasks, // Include fetched tasks
            notes, // Include fetched notes
          };
        }
    
        default: // For manually added cases
          return {
              ...caseData, // Spread caseData to retain all its fields
              title: `${caseData.petitioner || 'Unknown Petitioner'} vs ${caseData.respondent || 'Unknown Respondent'}`,
              court: caseData.court || 'Unknown Court',
              nextHearingDate: caseData.nextHearingDate || 'No Next Hearing Date',
              caseStage: caseData.caseStage || 'N/A',
              payments,
              invoices,
              tasks,
              notes,
              uploadedFiles: caseData.uploadedFiles || [],
              petitioner: caseData.petitioner || 'Unknown Petitioner', // Ensure these fields are explicitly set
              respondent: caseData.respondent || 'Unknown Respondent',
          };
      }
    };
    
    
  
    useEffect(() => {
      if (!userId || !caseData.id) {
          console.error("User ID or Case ID is missing. Aborting data fetch.");
          return;
      }
  
      const caseDocRef = doc(db, 'users', userId, 'CaseDetails', caseData.id);
  
      const fetchSubcollections = async () => {
          try {
              // Fetch Payments subcollection
              const paymentsCollectionRef = collection(db, 'users', userId, 'CaseDetails', caseData.id, 'Payments');
              const paymentsSnapshot = await getDocs(paymentsCollectionRef);
              const fetchedPayments = paymentsSnapshot.docs.map(doc => ({
                  id: doc.id,
                  ...doc.data(),
              }));
              setPayments(fetchedPayments);
  
              // Fetch Invoices subcollection
              const invoicesCollectionRef = collection(db, 'users', userId, 'CaseDetails', caseData.id, 'Invoices');
              const invoicesSnapshot = await getDocs(invoicesCollectionRef);
              const fetchedInvoices = invoicesSnapshot.docs.map(doc => ({
                  id: doc.id,
                  ...doc.data(),
              }));
              setInvoices(fetchedInvoices);
  
              // Fetch Tasks subcollection
              const tasksCollectionRef = collection(db, 'users', userId, 'CaseDetails', caseData.id, 'Tasks');
              const tasksSnapshot = await getDocs(tasksCollectionRef);
              const fetchedTasks = tasksSnapshot.docs.map(doc => ({
                  id: doc.id,
                  ...doc.data(),
              }));
              setTasks(fetchedTasks);
  
              // Fetch Notes subcollection
              const notesCollectionRef = collection(db, 'users', userId, 'CaseDetails', caseData.id, 'Notes');
              const notesSnapshot = await getDocs(notesCollectionRef);
              const fetchedNotes = notesSnapshot.docs.map(doc => ({
                  id: doc.id,
                  ...doc.data(),
              }));
              setNotes(fetchedNotes);
          } catch (error) {
              console.error("Error fetching subcollections:", error);
          }
      };
  
      const unsubscribe = onSnapshot(caseDocRef, (doc) => {
          if (doc.exists()) {
              try {
                  const snapshotData = parseCaseData(doc.data());
                  setCaseDetail(snapshotData); // Update case details state
                  fetchSubcollections(); // Fetch subcollections for the case
              } catch (error) {
                  console.error("Error parsing case data:", error);
              }
          } else {
              console.log("Document does not exist");
          }
      });
  
      return () => unsubscribe();
  }, [userId, caseData.id]);
  
  
  const handleDeleteCase = async () => {
        const confirmDelete = window.confirm("Are you sure you want to delete this case?");
        if (!confirmDelete) return;

        if (!userId || !caseDetail.id) {
            alert("User ID or Case ID is missing. Unable to delete the case.");
            return;
        }

        try {
            // Firestore reference to the case document
            const caseDocRef = doc(db, 'users', userId, 'CaseDetails', caseDetail.id);

            // Delete the document
            await deleteDoc(caseDocRef);

            alert("Case deleted successfully.");
            // Optionally, redirect or remove the case details from the UI
        } catch (error) {
            console.error("Error deleting case:", error);
            alert("An error occurred while deleting the case. Please try again.");
        }
    };
  
    return (
      <div>
  {/* Display Court Type */}
  <div className="card" style={{ marginTop: '20px' }}>
  <h3>
      {caseDetail.caseType === 'HighCourt'
        ? 'High Court'
        : caseDetail.caseType === 'CAT'
        ? 'Central Administrative Tribunal'
        : caseDetail.caseType === 'DistrictCourt'
        ? 'District Court'
        : caseDetail.court || 'Unknown Court'} {/* For manual cases, fallback to `court` or 'Unknown Court' */}
    </h3>

    {/* Display Case Number or CNR Number */}
    <div>
      {caseDetail.caseType !== 'CAT' && caseDetail.caseType && (
        <p>
          <strong>CNR Number: </strong>
          {caseDetail.caseType === 'HighCourt'
            ? caseDetail.caseDetails?.cnrNumber || "Unknown CNR"
            : caseDetail.caseType === 'DistrictCourt'
            ? caseDetail.caseDetails?.cnrNumber || "Unknown CNR"
            : "N/A"}
        </p>
      )}
      

  {/* Display Registration Number (Not for CAT cases) */}
  {caseDetail.caseType !== 'CAT' && (
    <p>
      <strong>Registration Number: </strong>
      {caseDetail.caseType === 'HighCourt'
        ? caseDetail.caseDetails?.registrationNumber || "Unknown Registration Number"
        : caseDetail.caseType === 'DistrictCourt'
        ? caseDetail.caseDetails?.registrationNumber || "Unknown Registration Number"
        : "N/A"}
    </p>
  )}
</div>

<div>
  {/* Display Case Number (Only for CAT cases) */}
  {caseDetail.caseType === 'CAT' && (
    <p>
      <strong>Case#: </strong>
      {caseDetail.caseNumber || "Unknown Case Number"}
    </p>
  )}
</div>



    {/* Display Court Details for District Court */}
    {caseDetail.caseType === 'DistrictCourt' && (
      <p><strong>Court:</strong> {caseDetail.courtNumber || "Unknown Court"}</p>
    )}

    {/* Display Court Details for CAT */}
    {caseDetail.caseType === 'CAT' && (
      <p><strong>Court: </strong> {caseDetail.court || "Central Administrative Tribunal"}</p>
    )}

    {/* Display Next Hearing Date */}
    <p>
      <strong>Next Hearing Date: </strong> 
      {caseDetail.nextHearingDate || "No Next Hearing Date"}
    </p>

    {/* Display Judge for High Court */}
    {caseDetail.caseType === 'HighCourt' && 
      caseDetail.case_hearing_history?.length > 1 && (
        <p>
          <strong>Judge:</strong> 
          {caseDetail.case_hearing_history[1]?.business || "N/A"}
        </p>
      )}

  
      {/* Display Petitioners */}
<p>
  <strong>Petitioners: </strong>
  {caseDetail.caseType === 'CAT'
    ? caseDetail.petitioners || "N/A" 
    : caseDetail.caseType 
      ? caseDetail.petitionerAdvocate || "Unknown Petitioners" 
      : caseDetail.petitioner || "Unknown Petitioners"}
</p>

{/* Display Respondents */}
<p>
  <strong>Respondents: </strong>
  {caseDetail.caseType === 'CAT'
    ? caseDetail.respondents || "N/A" 
    : caseDetail.caseType 
      ? caseDetail.respondentAdvocate || "Unknown Respondents" 
      : caseDetail.respondent || "Unknown Respondents"}
</p>



<div>
  {/* Display Acts */}
  <div className="acts-section">
    <h3>Acts</h3>
    {caseDetail.acts && caseDetail.acts.length > 0 ? (
      <ul>
        {caseDetail.acts
          .filter(actItem => actItem.act && actItem.section && actItem.act !== "N/A" && actItem.section !== "N/A") // Exclude empty or "N/A" entries
          .map((actItem, index) => (
            <li key={index}>
              <strong>Act:</strong> {actItem.act}, <strong>Section:</strong> {actItem.section}
            </li>
          ))}
        {/* Show fallback if no valid acts remain */}
        {caseDetail.acts.filter(actItem => actItem.act && actItem.section && actItem.act !== "N/A" && actItem.section !== "N/A").length === 0 && (
          <p>No Acts Specified</p>
        )}
      </ul>
    ) : (
      <p>No Acts Specified</p>
    )}
  </div>
</div>



      </div>

      

      <div className="card">

        {/* ChatGPT Suggestions */}
        <h3>Tips for Next Hearing:-</h3>
        <p>{caseData.chatGptSuggestions}</p>
        </div>

        <div className="card">

        <div className="billing-section">
    <h3>Billing</h3>

    {/* Fee Received Section */}
    <h4>Fee Received</h4>
    {payments && payments.length > 0 ? (
  <>
    <table className="billing-table">
      <thead>
        <tr>
          <th>Amount</th>
          <th>Description</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
      {payments.map((payment, index) => (
          <tr key={index}>
            <td>{payment.amount}</td>
            <td>{payment.description}</td>
            <td>
              {payment.date && payment.date.seconds
                ? new Date(payment.date.seconds * 1000).toLocaleDateString() // Firestore Timestamp
                : 'Invalid Date'}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
    <p>
  <strong>Total Fee Received:</strong>{" "}
  {(payments || []).reduce((total, payment) => total + Number(payment.amount), 0)}
</p>
  </>
) : (
  <p>No fees received yet.</p>
)}

<button onClick={() => setIsFeeModalOpen(true)}>Fee Received</button>

</div>
<h4>Pending Invoices</h4>
{invoices && invoices.length > 0 ? (
  <table className="billing-table">
    <thead>
      <tr>
        <th>Amount</th>
        <th>Description</th>
        <th>Date</th>
        <th>Due Date</th> {/* New column for Due Date */}
        <th>Action</th> {/* New column for Paid button */}
      </tr>
    </thead>
    <tbody>
      {invoices.map((invoice, index) => (
        <tr key={index}>
          <td>{invoice.amount}</td>
          <td>{invoice.description}</td>
          <td>
            {invoice.date && invoice.date.seconds
              ? new Date(invoice.date.seconds * 1000).toLocaleDateString() // Firestore Timestamp
              : invoice.date instanceof Date
              ? invoice.date.toLocaleDateString() // JS Date Object
              : "Invalid Date"}
          </td>
          <td>
            {invoice.dueDate && invoice.dueDate.seconds
              ? new Date(invoice.dueDate.seconds * 1000).toLocaleDateString() // Firestore Timestamp
              : invoice.dueDate instanceof Date
              ? invoice.dueDate.toLocaleDateString() // JS Date Object
              : "Invalid Date"}
          </td>
          <td>
            <button
              onClick={() => handleInvoicePaid(invoice)}
              style={{
                padding: "8px 12px",
                backgroundColor: "#4CAF50",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Paid
            </button>
          </td>
        </tr>
      ))}
    </tbody>
  </table>
) : (
  <p>No pending invoices.</p>
)}

    {/* Buttons to Open Modals */}
    <button onClick={() => setIsInvoiceModalOpen(true)}>Generate Invoice</button>
</div>
{/* Fee Received Modal */}
{isFeeModalOpen && (
  <div className="modal">
    <div className="modal-content">
      <h4>Enter Fee Details</h4>
      <input
        type="text"
        placeholder="Amount"
        value={paymentData.amount}
        onChange={(e) => setPaymentData({ ...paymentData, amount: e.target.value })}
      />
      <input
        type="text"
        placeholder="Description"
        value={paymentData.description}
        onChange={(e) => setPaymentData({ ...paymentData, description: e.target.value })}
      />
      <input
        type="date"
        defaultValue={paymentData.date} // set default value to current date
        onChange={(e) => setPaymentData({ ...paymentData, date: e.target.value })}
      />
      <button onClick={handleFeeReceived}>Save</button>
      <button onClick={() => setIsFeeModalOpen(false)}>Cancel</button>
    </div>
  </div>
)}

        {/* Generate Invoice Modal */}
{isInvoiceModalOpen && (
    <div className="modal">
        <div className="modal-content">
            <h4>Enter Invoice Details</h4>
            
            {/* Input for Amount */}
            <input
                type="number"
                placeholder="Amount"
                value={invoiceData.amount}
                onChange={(e) => setInvoiceData({ ...invoiceData, amount: e.target.value })}
            />
            
            {/* Input for Description */}
            <input
                type="text"
                placeholder="Description"
                value={invoiceData.description}
                onChange={(e) => setInvoiceData({ ...invoiceData, description: e.target.value })}
            />
            
            {/* Input for Invoice Date */}
            <input
                type="date"
                value={invoiceData.date || new Date().toISOString().substr(0, 10)} // Default to today's date
                onChange={(e) => setInvoiceData({ ...invoiceData, date: e.target.value })}
            />
            
            {/* Input for Due Date */}
            <input
                type="date"
                placeholder="Due Date"
                value={invoiceData.dueDate}
                onChange={(e) => setInvoiceData({ ...invoiceData, dueDate: e.target.value })}
            />
            
            {/* Generate Invoice Button */}
            <button onClick={handleGenerateInvoice}>
                Generate Invoice PDF
            </button>
            
            {/* Cancel Button */}
            <button onClick={() => setIsInvoiceModalOpen(false)}>Cancel</button>
        </div>
    </div>
)}


<div className="card">

{/* Notes Section */}
<div className="notes-section" style={{ marginTop: '40px', marginBottom: '20px' }}>
  <h3>Notes</h3>
  {notes.length > 0 ? (
    <ul style={{ listStyleType: 'none', padding: 0 }}>
      {notes.map((note) => {
        const date = note.date.seconds
          ? new Date(note.date.seconds * 1000).toLocaleDateString() // Firestore Timestamp
          : new Date(note.date).toLocaleDateString(); // Regular Date

        return (
          <li key={note.id} style={{ marginBottom: '10px' }}>
            <span><strong>{date}:</strong> {note.content}</span>
          </li>
        );
      })}
    </ul>
  ) : (
    <p>No notes available.</p>
  )}

  {/* Add Note Button */}
  <button style={{ marginTop: '10px' }} onClick={() => setIsNoteModalOpen(true)}>
    Add Note
  </button>

  {/* Note Modal */}
  {isNoteModalOpen && (
    <div className="modal">
      <div className="modal-content">
        <h4>Enter Note Details</h4>
        <textarea
          placeholder="Note Content"
          value={noteData.content}
          onChange={(e) => setNoteData({ ...noteData, content: e.target.value })}
          style={{ width: '100%', height: '100px' }}
        />
        <input
          type="date"
          value={noteData.date}
          onChange={(e) => setNoteData({ ...noteData, date: e.target.value })}
          style={{ marginTop: '10px' }}
        />
        <input
          type="text"
          placeholder="Type"
          value={noteData.type}
          onChange={(e) => setNoteData({ ...noteData, type: e.target.value })}
          style={{ marginTop: '10px' }}
        />
        <button onClick={handleAddNote} style={{ marginTop: '10px' }}>Save Note</button>
        <button onClick={() => setIsNoteModalOpen(false)} style={{ marginTop: '10px' }}>Cancel</button>
      </div>
    </div>
  )}
</div>
{/* To-Do Section */}
<div className="todos-section" style={{ marginBottom: '20px' }}>
  <h3>To-Dos</h3>
  {tasks && tasks.length > 0 ? (
    <ul style={{ listStyleType: 'none', padding: 0 }}>
      {tasks.map((todo) => {
        const date = todo.date && todo.date.seconds
          ? new Date(todo.date.seconds * 1000).toLocaleDateString() // Handle Firestore Timestamp
          : new Date(todo.date).toLocaleDateString(); // Handle regular Date

        return (
          <li
            key={todo.taskId} // Ensure `taskId` is used as a unique key
            style={{
              marginBottom: '10px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <span>
              <strong>{date}:</strong> {todo.description} <em>({todo.assignedTo || "Unassigned"})</em>
            </span>
            <button
              onClick={() => handleCompleteTodo(todo)}
              style={{
                padding: '8px 12px',
                marginLeft: '20px',
                backgroundColor: todo.isCompleted ? '#999' : '#4CAF50',
                color: 'white',
                border: 'none',
                borderRadius: '5px',
                cursor: todo.isCompleted ? 'not-allowed' : 'pointer',
              }}
              disabled={todo.isCompleted} // Disable button if already completed
            >
              {todo.isCompleted ? "Completed" : "Complete"}
            </button>
          </li>
        );
      })}
    </ul>
  ) : (
    <p>No to-dos available.</p>
  )}

  {/* Add To-Do Button */}
  <button style={{ marginTop: '10px' }} onClick={() => setIsTodoModalOpen(true)}>Add To-Do</button>
</div>


{/* To-Do Modal */}
{isTodoModalOpen && (
  <div className="modal">
    <div className="modal-content">
      <h4>Enter To-Do Details</h4>

      {/* To-Do Description */}
      <input
        type="text"
        placeholder="Description"
        value={todoData.description}
        onChange={(e) => setTodoData({ ...todoData, description: e.target.value })}
        style={{ marginBottom: '10px', width: '100%' }}
      />

      {/* Due Date Picker */}
      <label>
        <strong>Due Date:</strong>
        <input
          type="date"
          value={todoData.date}
          onChange={(e) => setTodoData({ ...todoData, date: e.target.value })}
          style={{ marginTop: '10px', width: '100%' }}
        />
      </label>

      {/* Assign To Dropdown */}
      <label>
        <strong>Assign to:</strong>
        <select
          value={todoData.assignedTo}
          onChange={(e) => setTodoData({ ...todoData, assignedTo: e.target.value })}
          style={{ marginTop: '10px', width: '100%' }}
        >
          {/* Default option */}
          <option value="">Select Team Member</option>

          {/* Add Self option */}
          <option value="Self">Self</option>

          {/* Populate team members */}
          {teamMembers.map((member) => (
            <option key={member.id} value={member.name}>
              {member.name} - {member.mobile}
            </option>
          ))}
        </select>
      </label>

      {/* Completed Checkbox */}
      <label>
        <input
          type="checkbox"
          checked={todoData.isCompleted}
          onChange={(e) => setTodoData({ ...todoData, isCompleted: e.target.checked })}
          style={{ marginTop: '10px' }}
        />
        Completed
      </label>

      {/* Save and Cancel Buttons */}
      <div style={{ marginTop: '20px' }}>
        <button onClick={handleAddTodo} style={{ marginRight: '10px' }}>
          Save
        </button>
        <button onClick={() => setIsTodoModalOpen(false)}>Cancel</button>
      </div>
    </div>
  </div>
)}

</div>

<div className="card">

{/* Upload File Section */}
<div className="file-upload-section" style={{ marginTop: '40px', marginBottom: '20px' }}>
                <h3>Upload Documents</h3>
                <input type="file" onChange={handleFileChange} />
                <button onClick={handleFileUpload} disabled={isUploading}>
                    {isUploading ? 'Uploading...' : 'Upload'}
                </button>
            </div>

            {/* Display Uploaded Files */}
            <div className="uploaded-files-section" style={{ marginTop: '40px', marginBottom: '20px' }}>
                <h3>Uploaded Files</h3>
                {uploadedFiles && uploadedFiles.length > 0 ? (
                    <ul style={{ listStyleType: 'none', padding: 0 }}>
                        {uploadedFiles.map((file, index) => (
                            <li key={index} style={{ marginBottom: '10px' }}>
                                <a href={file.url} target="_blank" rel="noopener noreferrer">
                                    {file.name}
                                </a>
                            </li>
                        ))}
                    </ul>
                ) : (
                    <p>No files uploaded yet.</p>
                )}
            </div>
        
            </div>
            <div className="card">

    {/* Case Status Section */}
<div className="expandable-section" onClick={handleToggleCaseStatus} style={{ cursor: 'pointer' }}>
  <h3>Case Status {isCaseStatusExpanded ? '▲' : '▼'}</h3>
  
  {/* Check if Case Status is Expanded */}
  {isCaseStatusExpanded && (
    <div className="expandable-content">
      {/* HighCourt Case Details */}
      {caseDetail?.caseType === "HighCourt" && caseDetail?.case_status ? (
        <>
          <p><strong>Stage:</strong> {caseDetail.case_status["Stage of Case"] || "N/A"}</p>
          <p><strong>Bench Type:</strong> {caseDetail.case_status["Bench Type"] || "N/A"}</p>
          <p><strong>Coram:</strong> {caseDetail.case_status["Coram"] || "N/A"}</p>
          <p><strong>Judicial Branch:</strong> {caseDetail.case_status["Judicial Branch"] || "N/A"}</p>
          <p><strong>State:</strong> {caseDetail.case_status["State"] || "N/A"}</p>
        </>
      ) : null}

      {/* DistrictCourt Case Details */}
      {caseDetail?.caseType === "DistrictCourt" && caseDetail?.caseStatus ? (
        <>
          <p><strong>Case Stage:</strong> {caseDetail.caseStatus["Case Stage"] || "N/A"}</p>
          <p><strong>Case Type:</strong> {caseDetail.caseDetails?.caseType || "N/A"}</p>
        </>
      ) : null}

      {/* CAT Case Details */}
      {caseDetail?.caseType === "CAT" && (
        <>
          <p><strong>Court Number:</strong> {caseDetail.court || "N/A"}</p>
          <p><strong>Diary Number:</strong> {caseDetail.diaryNumber || "N/A"}</p>
          <p><strong>Filing Date:</strong> {caseDetail.filingDate || "N/A"}</p>
          <p><strong>Stage:</strong> {caseDetail.stage || "N/A"}</p>
          <p><strong>Status:</strong> {caseDetail.status || "N/A"}</p>
        </>
      )}
    </div>
  )}
</div>


{/* Expandable History in Table Format */}
<div className="expandable-section" onClick={handleToggleHistory} style={{ cursor: 'pointer' }}>
  <h3>History {isHistoryExpanded ? '▲' : '▼'}</h3>
  {isHistoryExpanded && (
    <div className="expandable-content">
      <table style={{ width: '100%', borderCollapse: 'collapse' }}>
        <thead>
          <tr>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Hearing Date</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Business On Date</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Judge</th>
            <th style={{ border: '1px solid #ddd', padding: '8px' }}>Purpose</th>
          </tr>
        </thead>
        <tbody>
          {caseDetail.caseType === "HighCourt" && caseDetail.hearing_history?.length > 0 ? (
            caseDetail.hearing_history.map((item, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.hearing_date || 'N/A'}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.business_on_date || 'N/A'}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.judge || 'N/A'}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.purpose || 'N/A'}</td>
              </tr>
            ))
          ) : caseDetail.caseType === "CAT" && caseDetail.caseProceedings?.length > 0 ? (
            caseDetail.caseProceedings.map((proceeding, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{proceeding.hearingDate || 'N/A'}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{proceeding.courtName || 'N/A'}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{proceeding.nature || 'N/A'}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{proceeding.purpose || 'N/A'}</td>
              </tr>
            ))
          ) : caseDetail.caseType === "DistrictCourt" && caseDetail.caseHistory?.length > 0 ? (
            caseDetail.caseHistory.map((item, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.hearingDate || 'N/A'}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.businessOnDate || 'N/A'}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.judge || 'N/A'}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.purpose || 'N/A'}</td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={4} style={{ textAlign: 'center', padding: '8px' }}>
                No case history available
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  )}
</div>



{/* Expandable Orders in Table Format */}
<div className="expandable-section" onClick={handleToggleOrders} style={{ cursor: 'pointer' }}>
  <h3>
    Orders {isOrdersExpanded ? '▲' : '▼'}
  </h3>
  {isOrdersExpanded && (
    <div className="expandable-content">
      {caseDetail.caseType === "CAT" && caseDetail.orderDetails && caseDetail.orderDetails.length > 0 ? (
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Order Date</th>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Order Link</th>
            </tr>
          </thead>
          <tbody>
            {caseDetail.orderDetails.map((order, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{order.orderDate || 'N/A'}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  <a href={order.orderUrl} target="_blank" rel="noopener noreferrer">View PDF</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : caseDetail.caseType !== "CAT" && caseDetail.orders && caseDetail.orders.length > 0 ? (
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Order Date</th>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Judge</th>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Order On</th>
              <th style={{ border: '1px solid #ddd', padding: '8px' }}>Order Link</th>
            </tr>
          </thead>
          <tbody>
            {caseDetail.orders.map((order, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{order.order_date || 'N/A'}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{order.judge || 'N/A'}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{order.order_on || 'N/A'}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>
                  <a href={order.pdf_download_url} target="_blank" rel="noopener noreferrer">View PDF</a>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>No orders available.</p>
      )}
    </div>
  )}
</div>

<div style={{ marginTop: '20px', textAlign: 'center' }}>
                <p style={{ fontWeight: 'bold', color: 'red' }}>Is this not your case?</p>
                <button
                    onClick={handleDeleteCase}
                    style={{
                        padding: '10px 20px',
                        backgroundColor: 'red',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer',
                    }}
                >
                    DELETE
                </button>
            </div>
        

</div>

 {/* Footer */}
            <footer style={{ backgroundColor: '#f8f8f8', padding: '20px', textAlign: 'center', borderTop: '1px solid #ddd' }}>
                <p style={{ margin: '5px 0', fontSize: '14px', color: '#555' }}>
                    Case data is fetched from <a href="https://ecourts.gov.in" target="_blank" rel="noopener noreferrer">ecourts.gov.in</a> and GenLawyers does not assure accuracy of the same.
                </p>
                <p style={{ margin: '5px 0', fontSize: '14px', fontWeight: 'bold' }}>
                    All Rights Reserved 2024 &copy; <a href="https://genlawyers.com" target="_blank" rel="noopener noreferrer">GenLawyers.com</a>
                </p>
            </footer>
      </div>

      
    );
  }
  

export default CaseDetail;
