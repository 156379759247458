import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import MultiStepFormLayout from './MultiStepFormLayout';
import './ReviewSelection.css';
import { db, auth } from '../firebaseConfig';
import { doc, setDoc } from 'firebase/firestore';

function ReviewSelection({ userData }) {
  const navigate = useNavigate();
  const userId = auth.currentUser ? auth.currentUser.uid : null;
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleRegister = async () => {
    if (!userId) {
      console.error("User is not authenticated.");
      setErrorMessage('User not authenticated. Please log in.');
      return;
    }
  
    setLoading(true); // Start loading
    setErrorMessage(null); // Clear previous errors
  
    try {
      // Prepare API call data
      const highCourtStates = Object.keys(userData.selectedHCStates || {});
      const highCourtBenches = highCourtStates.flatMap(
        state => userData.selectedHCStates[state] || []
      );
  
      const jsonPayloads = {
        highCourtPayloads: highCourtStates.flatMap(state =>
          userData.selectedHCStates[state].map(bench => ({
            stateName: state,
            benchName: bench,
            advocateName: userData.advocateName,
            userId,
          }))
        ),
        catPayload: {
          benchName: userData.selectedCATBench,
          advocateName: userData.advocateName,
          userId,
        },
      };
  
      console.log('High Court Payloads:', JSON.stringify(jsonPayloads.highCourtPayloads, null, 2));
      console.log('CAT Payload:', JSON.stringify(jsonPayloads.catPayload, null, 2));
  
      // Save data and trigger API calls in the background
      saveToFirestore(userId, userData).catch(err => console.error('Error saving to Firestore:', err));
      triggerApiCalls(jsonPayloads).catch(err => console.error('Error in API calls:', err));
  
      // Navigate to dashboard immediately
      navigate(`/dashboard/${userId}`, { state: { fromRegistration: true } });
    } catch (error) {
      console.error('Error during registration:', error);
      setErrorMessage('An error occurred during registration. Please try again.');
    } finally {
      setLoading(false); // Stop loading
    }
  };
  
  const triggerApiCalls = async ({ highCourtPayloads, catPayload }) => {
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    // High Court API Calls
    const highCourtCalls = highCourtPayloads.map(async payload => {
      try {
        const response = await fetch(`${apiBaseUrl}/scrape-high-court`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(payload),
        });
        const data = await response.json();
        if (response.ok) {
          console.log('High Court API success:', data.message);
        } else {
          console.error('High Court API error:', data.message);
        }
      } catch (error) {
        console.error('High Court API call failed:', error);
      }
    });

    // CAT API Call
    const catCall = (async () => {
      try {
        const response = await fetch(`${apiBaseUrl}/CAT_cases`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(catPayload),
        });
        const data = await response.json();
        if (response.ok) {
          console.log('CAT API success:', data.message);
        } else {
          console.error('CAT API error:', data.message);
        }
      } catch (error) {
        console.error('CAT API call failed:', error);
      }
    })();

    // Run all API calls in parallel
    await Promise.all([...highCourtCalls, catCall]);
  };

  const saveToFirestore = async (userId, userData) => {
    const courtComplexes = Object.entries(userData.selectedComplexes || {}).flatMap(([state, districtComplexes]) =>
      Object.entries(districtComplexes).flatMap(([district, complexes]) =>
        complexes.map(complex => `${state} - ${district} - ${complex}`)
      )
    );

    const highCourtStates = Object.keys(userData.selectedHCStates || {});
    const highCourtBenches = highCourtStates.flatMap(state => userData.selectedHCStates[state] || []);

    const userProfile = {
      advocate_name: userData.advocateName,
      court_complexes: courtComplexes,
      high_court_states: highCourtStates,
      high_court_benches: highCourtBenches,
      bench_state: userData.selectedCATBench || null,
      user_id: userId,
    };

    try {
      await setDoc(doc(db, 'users', userId), userProfile, { merge: true });
      console.log('User data saved successfully.');
    } catch (error) {
      console.error('Error saving user data to Firestore:', error);
      throw new Error('Failed to save user data.');
    }
  };

  return (
    <MultiStepFormLayout 
      step={7} 
      totalSteps={7} 
      onNext={handleRegister} 
      onBack={() => navigate(-1)}
      isNextDisabled={loading}
    >
      {loading && <div className="loader">Loading...</div>} {/* Loader */}
      <div className="review-section">
        <h3>Personal Information</h3>
        <div className="personal-info">
          <p><strong>Name:</strong> {userData.advocateName}</p>
          <p><strong>Email:</strong> {userData.email}</p>
          <p><strong>Mobile:</strong> {userData.mobile}</p>
          <p><strong>License Number:</strong> {userData.licenseNumber}</p>
        </div>
      </div>

      {userData.selectedStates.length > 0 && (
        <div className="review-section">
          <h3>Selected States</h3>
          <ul className="review-list">
            {userData.selectedStates.map((state, index) => (
              <li key={index}>{state}</li>
            ))}
          </ul>
        </div>
      )}

      {Object.keys(userData.selectedComplexes).length > 0 && (
        <div className="review-section">
          <h3>Court Complexes</h3>
          {Object.entries(userData.selectedComplexes).map(([state, districtComplexes], index) => (
            <div key={index} className="complex-state">
              <h4>{state}</h4>
              {Object.entries(districtComplexes).map(([district, complexes], i) => (
                <div key={i} className="complex-district">
                  <strong>{district}</strong>
                  <ul className="review-list">
                    {complexes.map((complex, j) => (
                      <li key={j}>{complex}</li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          ))}
        </div>
      )}

      {Object.keys(userData.selectedHCStates).length > 0 && (
        <div className="review-section">
          <h3>High Court Selections</h3>
          {Object.entries(userData.selectedHCStates).map(([state, benches], index) => (
            <div key={index} className="high-court-state">
              <strong>{state}</strong>
              <ul className="review-list">
                {benches.length > 0 ? benches.map((bench, i) => (
                  <li key={i}>{bench}</li>
                )) : <li>No benches selected</li>}
              </ul>
            </div>
          ))}
        </div>
      )}

      {userData.selectedCATBench && (
        <div className="review-section">
          <h3>Selected CAT Bench</h3>
          <p>{userData.selectedCATBench}</p>
        </div>
      )}
    </MultiStepFormLayout>
  );
}

export default ReviewSelection;
