import React, { useEffect, useLayoutEffect, useState, useRef } from 'react';
import { db, auth } from '../firebaseConfig'; // Make sure to import auth
import { collection, doc, getDoc, getDocs, onSnapshot } from 'firebase/firestore';
import './Dashboard.css';
import { useCallback } from 'react';
import { updateDoc, arrayUnion } from 'firebase/firestore';
import CaseDetail from './CaseDetail'; // Import the CaseDetail component
import TopBar from './TopBar'; // Import TopBar component
import { useUser } from '../UserContext'; // Import the useUser hook
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import waitingAnimation from '../assets/waiting.json';
import Lottie from 'lottie-react';
import moment from 'moment';


function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation(); // Use useLocation hook
  const { userData } = useUser(); // Get userData from context
  const [cases, setCases] = useState([]);
  const [filteredCases, setFilteredCases] = useState([]);
  const fromRegistration = location.state?.fromRegistration || false; // Check if user came from registration
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState('');
  const [selectedCase, setSelectedCase] = useState(null); // State for selected case
  const [isMobileView, setIsMobileView] = useState(false); // State to toggle mobile view
  const [userId, setUserId] = useState(useParams().userId || null); // Use useState to define userId and setUserId
  const [isCaseDetailVisible, setCaseDetailVisible] = useState(false);
  const caseDetailRef = useRef(null); // Define a ref for the case detail container

  useEffect(() => {
    if (fromRegistration) {
      const timer = setTimeout(() => {
        // Perform additional actions after 10 minutes if needed
      }, 10 * 60 * 1000); // 10 minutes
      return () => clearTimeout(timer); // Clean up the timer
    }
  }, [fromRegistration]);

  useLayoutEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 768);
    };
  
    window.addEventListener('resize', handleResize);
    handleResize(); // Call initially to set the correct layout mode
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const parseCase = useCallback((document) => {
    const caseType = document.get("caseType");

    switch (caseType) {
        case "HighCourt":
            return createHighCourtCase(document);
        case "DistrictCourt":
            return createDistrictCourtCase(document);
        case "CAT":
            return createCATCase(document);
        default:
            // Parse manual cases or cases without specific type
            return {
                id: document.id,
                title: `${document.get("petitioner") || "Unknown"}\nversus\n${document.get("respondent") || "Unknown"}`, // Add newline for "vs"
                petitioner: document.get("petitioner") || "Unknown", // Separate fields
                respondent: document.get("respondent") || "Unknown",
                nextDate: document.get("nextHearingDate") || "No Next Hearing Date",
                court: document.get("court") || "Unknown Court",
                caseStage: document.get("caseStage") || "N/A",
                createdAt: document.get("createdAt") || "Unknown Creation Date",
                petitionerAdvocate: document.get("petitionerAdvocate") || "Unknown Advocate",
                respondentAdvocate: document.get("respondentAdvocate") || "Unknown Advocate",
                caseType: "Manual", // Mark these as manual
            };
    }
}, []);


  const fetchCases = useCallback(() => {
    setIsLoading(true);
    const casesRef = collection(db, 'users', userId, 'CaseDetails');

    // Use Firestore's onSnapshot to get real-time updates
    const unsubscribe = onSnapshot(casesRef, (querySnapshot) => {
        const casesData = querySnapshot.docs.map(doc => parseCase(doc));

        // Get today's date in IST (Indian Standard Time)
        const today = new Date();
        today.setHours(0, 0, 0, 0); // Reset to midnight to compare only dates

       // Sort the cases by next hearing date (nearest first)
        const sortedCases = casesData.sort((a, b) => {
          const dateA = parseDate(a.nextDate);
          const dateB = parseDate(b.nextDate);

          if (dateA.getTime() === today.getTime() && dateB.getTime() === today.getTime()) {
              return 0; // If both dates are today, keep the original order
          }
          if (dateA.getTime() === today.getTime()) return -1; // Today's cases come first
          if (dateB.getTime() === today.getTime()) return 1; // Today's cases come first

          if (dateA < today && dateB < today) {
              return dateB - dateA; // Sort past dates
          }
          if (dateA < today) return 1; // Past dates come last
          if (dateB < today) return -1; // Future dates come first

          return dateA - dateB; // Sort future dates
        });

        setCases(sortedCases);
        setFilteredCases(sortedCases);
        setIsLoading(false);
    });

    return () => unsubscribe(); // Clean up the listener on unmount
}, [userId]);

  
  useEffect(() => {
    const storedUserId = userId || localStorage.getItem('userId');
    if (storedUserId) {
        setUserId(storedUserId);
        fetchCases();  // Fetch cases once userId is available
    } else {
        navigate('/login');
    }
}, [userId, fetchCases, navigate]);

 // Helper function to parse the nextDate string into a Date object
const parseDate = (dateString) => {
  if (!dateString) return new Date(8640000000000000); // Set to distant future if no date

  // Remove ordinal suffixes
  const cleanedDateString = dateString.replace(/(st|nd|rd|th)/g, '').trim();

  // Normalize the date format by adding leading zero to the month if needed
  const parts = cleanedDateString.split('/');
  if (parts.length === 3) {
      parts[1] = parts[1].padStart(2, '0'); // Add leading zero to month
      return new Date(parts.join('/')); // Format back to 'DD/MM/YYYY'
  }

  // Define various date formats to parse
  const dateFormats = [
      "DD/MM/YYYY", // Format: 25/09/2024
      "DD-MM-YYYY", // Format: 25-09-2024
      "DD MMMM YYYY", // Format: 26 September 2024
      "DD MMM YYYY", // Format: 26 Sep 2024
      "MMMM DD, YYYY", // Format: September 26, 2024
      "MMMM DD YYYY", // Format: September 26 2024
  ];

  // Try to parse the date with moment.js
  for (const format of dateFormats) {
      const momentDate = moment(cleanedDateString, format, true);
      if (momentDate.isValid()) {
          return momentDate.toDate();
      }
  }

  // If the date is invalid, return a distant future date
  return new Date(8640000000000000);
};


  useEffect(() => {
    fetchCases(); // Now it will run without warnings
  }, [fetchCases]);  // Add fetchCases as a dependency


  const createHighCourtCase = (document) => {
    const petitionerAdvocate = document.get("petitioner_advocate") || "Unknown Petitioner";
    const respondentAdvocate = document.get("respondent_advocate") || "Unknown Respondent";
    const caseStatus = document.get("case_status") || {};

    return {
      id: document.id,
      title: `${petitionerAdvocate}\nversus\n${respondentAdvocate}`, // Format title with "vs" on a new line
      nextDate: caseStatus["Next Hearing Date"] || "No Next Hearing Date",
      court: caseStatus["Coram"] || "Unknown Court",
      caseType: "High Court",

    };
  };


  const createDistrictCourtCase = (document) => {
    const petitionerAdvocate = document.get("petitioner") || "Unknown Petitioner";
    const respondentAdvocate = document.get("respondent") || "Unknown Respondent";
    const caseDetails = document.get("caseDetails") || {};
    const caseStatus = document.get("caseStatus") || {};
    const caseHistory = document.get("caseHistory") || [];
  
    return {
      id: document.id,
      title: `${petitionerAdvocate}\nversus\n${respondentAdvocate}`, // Format title with "vs" on a new line
      nextDate: caseStatus["Next Hearing Date"] || "No Next Hearing Date",
      court: caseStatus["Court Number and Judge"] || "Unknown Court",
      caseDetails: {
        cnrNumber: caseDetails["CNR Number"] || "Unknown CNR",
        caseType: caseDetails["Case Type"] || "Unknown Case Type",
        filingNumber: caseDetails["Filing Number"] || "Unknown Filing Number",
        registrationNumber: caseDetails["Registration Number"] || "Unknown Registration Number",
      },
      caseHistory: caseHistory.map((entry) => ({
        businessOnDate: entry.businessOnDate || "N/A",
        hearingDate: entry.hearingDate || "N/A",
        judge: entry.judge || "N/A",
        purpose: entry.purpose || "N/A",
      })),
      caseStatus: {
        caseStage: caseStatus["Case Stage"] || "N/A",
        nextHearingDate: caseStatus.nextHearingDate || "No Next Hearing Date",
        petitioner: caseStatus.petitioner || "Unknown Petitioner",
        respondent: caseStatus.respondent || "Unknown Respondent",
      },
      caseType: "District Court",
    };
  };
  

  const createCATCase = (document) => {
    const title = document.get("applicantRespondent") || "Unknown Title"; // Applicant vs Respondent
    const nextDate = document.get("nextHearing") || "No Next Hearing Date"; // Next hearing date
    const court = document.get("courtNumber") === "N/A" ? "Central Administrative Tribunal" : document.get("courtNumber"); // Default court name

    const caseNumber = document.get("caseNumber") || "Unknown Case Number"; // Case Number
    const status = document.get("status") || "Unknown Status"; // Case status

    return {
        id: document.id,
        title,
        nextDate,
        court,
        caseNumber,
        status,
        caseType: "Central Administrative Tribunal", // Explicitly set the case type
    };
};

  const handleSearch = (query) => {
    setSearchQuery(query);
    const filtered = cases.filter(c =>
      c.title?.toLowerCase().includes(query.toLowerCase()) ||
      (typeof c.petitionerAdvocate === 'string' && c.petitionerAdvocate.toLowerCase().includes(query.toLowerCase())) ||
      (typeof c.respondentAdvocate === 'string' && c.respondentAdvocate.toLowerCase().includes(query.toLowerCase()))
    );
    setFilteredCases(filtered);
  };

  useEffect(() => {
    if (window.innerWidth <= 768) { // Replace 768 with your mobile breakpoint
      const storedCase = sessionStorage.getItem('mobileSelectedCase');
      if (storedCase) {
        setSelectedCase(JSON.parse(storedCase));
        setCaseDetailVisible(true);
      }
    }
  }, []);
  
  
  
  const handleCaseClick = (caseData) => {
    // Clear any previous case in sessionStorage
    sessionStorage.removeItem('mobileSelectedCase');

    // Set new case data in sessionStorage for mobile persistence
    sessionStorage.setItem('mobileSelectedCase', JSON.stringify(caseData));
    setSelectedCase(caseData);

    // Make the case detail visible immediately
    setCaseDetailVisible(true);

    const caseDocRef = doc(db, 'users', userId, 'CaseDetails', caseData.id);
  
    // Fetch case details from Firestore
    const unsubscribe = onSnapshot(caseDocRef, (caseDoc) => {
        if (caseDoc.exists()) {
            const fullCaseDetails = caseDoc.data();
            const caseDetailData = {
                ...caseData,
                petitionerAdvocate: fullCaseDetails.Petitioners?.join(", ") || "Unknown Petitioner",
                respondentAdvocate: fullCaseDetails.Respondents?.join(", ") || "Unknown Respondent",
                caseType: fullCaseDetails.caseType || "Unknown Case Type",
                title: `${fullCaseDetails.Petitioners?.join(", ")} vs ${fullCaseDetails.Respondents?.join(", ")}`,
                caseNumber: fullCaseDetails.CNRNumber || "Unknown Case Number",
                nextHearingDate: fullCaseDetails.NextHearingDate || "No Next Hearing Date",
                courtNumber: fullCaseDetails["Court Number and Judge"] || "Unknown Court",
                caseStage: fullCaseDetails["Case Stage"] || "N/A",
                chatGptSuggestions: fullCaseDetails.ChatGPTSuggestions || "No suggestions available",
                payments: fullCaseDetails.payments || [],
                pendingInvoices: fullCaseDetails.pendingInvoices || [],
                notes: fullCaseDetails.notes || [],
                toDos: fullCaseDetails.toDos || [],
                orders: fullCaseDetails.orders || [],
                caseStatus: fullCaseDetails.case_status || {},
                caseHistory: fullCaseDetails.CaseHistory || [],
            };

            setSelectedCase(caseDetailData);

            // Scroll to the case detail if necessary
            if (caseDetailRef.current) {
                caseDetailRef.current.scrollIntoView({ behavior: 'smooth' });
            }

            // Add full-screen effect for mobile view
            if (isMobileView) {
                document.querySelector('.case-detail-container').classList.add('full-screen');
                document.querySelector('.case-list-container').classList.add('case-list-hidden'); // Hide case list on mobile
            }
        }
    });

    return () => {
      console.log("Cleaning up Firestore listener for case:", caseData.id);
      unsubscribe();
  };
};

  
  const handleBackToList = () => {
    setCaseDetailVisible(false);
    setTimeout(() => {
      setSelectedCase(null);
      if (isMobileView) {
        document.querySelector('.case-detail-container').classList.remove('full-screen');
        document.querySelector('.case-list-container').classList.remove('case-list-hidden');
      }
    }, 300);
  };
  
  return (
    <div className="dashboard-container">
      <div className={`case-list-container ${selectedCase && isMobileView ? 'hide-case-list' : ''}`}>
        <SearchBar value={searchQuery} onSearch={handleSearch} />
        {fromRegistration && !selectedCase ? (
          <div className="loading-animation">
            <h3>Your cases are being fetched and will appear in 5-10 minutes. Meanwhile, check our legal AI trained on Indian laws, document drafting, and other features.</h3>
            <Lottie
              animationData={waitingAnimation}
              autoplay
              loop
              style={{ height: '300px', width: '300px' }}
            />
          </div>
        ) : filteredCases.length === 0 ? (
          <p>No cases found</p>
        ) : (
          <div className="case-list">
            {filteredCases.map(c => (
              <CaseCard key={c.id} caseData={c} selectedCaseId={selectedCase?.id} onClick={() => handleCaseClick(c)} />
            ))}
          </div>
        )}
      </div>
  
      <div className={`case-detail-container ${isCaseDetailVisible ? 'show' : ''}`} ref={caseDetailRef}>
        {selectedCase ? (
          <>
            <button className="back-button" onClick={handleBackToList}>Back to Cases</button>
            <CaseDetail caseData={selectedCase} userId={userId} />
          </>
        ) : (
          <p>Select a case to view details.</p>
        )}
      </div>
    </div>
  );  
}


function SearchBar({ value, onSearch }) {
  return (
    <input
      type="text"
      value={value}
      onChange={(e) => onSearch(e.target.value)}
      placeholder="Search cases..."
      style={{
        padding: '10px',
        margin: '20px 0',
        width: '100%',
        boxSizing: 'border-box'
      }}
    />
  );
}

function CaseCard({ caseData, onClick, selectedCaseId }) {
  const isSelected = selectedCaseId === caseData.id;

  // Determine court type icon and color
  const getCourtIconAndColor = (caseType) => {
    switch (caseType) {
      case "High Court":
        return { icon: "🏛️", color: "teal" };
      case "District Court":
        return { icon: "⚖️", color: "blue" };
      case "Central Administrative Tribunal":
        return { icon: "🏢", color: "purple" };
      default:
        return { icon: "📜", color: "gray" }; // Default icon for manual cases
    }
  };

  // Determine priority icon based on next hearing date
  const getPriorityIcon = (nextDate) => {
    if (!nextDate) {
      return { icon: "❓", color: "gray" }; // Handle missing dates
    }
  
    let hearingDate;
  
    // Normalize the date string
    const normalizeDate = (dateString) => {
      // Remove ordinal suffixes like 'st', 'nd', 'rd', 'th'
      const cleanedDate = dateString.replace(/(\d+)(st|nd|rd|th)/, "$1").trim();
  
      // Handle Indian format (DD/MM/YYYY)
      if (/\d{2}\/\d{2}\/\d{4}/.test(cleanedDate)) {
        const [day, month, year] = cleanedDate.split("/");
        return new Date(`${year}-${month}-${day}`); // Convert to YYYY-MM-DD
      }
  
      // Parse other formats
      return new Date(cleanedDate);
    };
  
    try {
      hearingDate = normalizeDate(nextDate);
      if (isNaN(hearingDate)) {
        throw new Error("Invalid Date");
      }
    } catch (error) {
      console.warn("Invalid nextDate format:", nextDate);
      return { icon: "❓", color: "gray" }; // Invalid date format
    }
  
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Reset time to compare only dates
  
    if (hearingDate.toDateString() === today.toDateString()) {
      return { icon: "⏰", color: "red" }; // Today's hearing
    } else if (hearingDate > today && hearingDate - today <= 24 * 60 * 60 * 1000) {
      return { icon: "🔔", color: "orange" }; // Tomorrow's hearing
    } else if (hearingDate > today) {
      return { icon: "📅", color: "green" }; // Future hearing
    }
    return { icon: "⏳", color: "gray" }; // Past dates
  };
  

  const { icon: courtIcon, color: courtColor } = getCourtIconAndColor(caseData.caseType);
  const { icon: priorityIcon, color: priorityColor } = getPriorityIcon(caseData.nextDate);

  return (
    <div
      className={`case-card ${isSelected ? "selected" : ""}`}
      onClick={onClick}
      style={{
        padding: "20px",
        margin: "10px 0",
        border: "1px solid #ddd",
        borderRadius: "5px",
        cursor: "pointer",
        backgroundColor: isSelected ? "#e0e0e0" : "#fff",
        borderColor: isSelected ? "#6200EA" : "#ddd",
        transition: "background-color 0.3s ease, border-color 0.3s ease",
      }}
    >
      <p style={{ color: priorityColor }}>
         {priorityIcon} {caseData.nextDate || "No Next Hearing Date"}
      </p>
      <h4>
        {caseData.title.split("\n").map((line, index) => (
          <React.Fragment key={index}>
            {line.trim().toLowerCase() === "versus" ? (
              <span style={{ fontStyle: "italic", color: "grey" }}>{line}</span>
            ) : (
              line
            )}
          </React.Fragment>
        ))}
      </h4>

      {caseData.caseType && (
        <p style={{ color: courtColor, fontWeight: "bold" }}>
          {courtIcon} {caseData.caseType}
        </p>
      )}
      <p>
        {caseData.court || "Unknown Court"}
      </p>

      {/* High Court Specific Details */}
      {caseData.caseType === "High Court" && (
        <div>
         
        </div>
      )}

      {/* District Court Specific Details */}
      {caseData.caseType === "District Court" && (
        <div>
          <p>
            <strong>Case Type:</strong> {caseData.caseDetails?.caseType || "N/A"}
          </p>
        </div>
      )}

      {/* CAT Specific Details */}
      {caseData.caseType === "Central Administrative Tribunal" && (
        <div>
          <p>
            <strong>Case Number:</strong> {caseData.caseNumber || "Unknown Case Number"}
          </p>
          <p>
            <strong>Status:</strong> {caseData.status || "Unknown Status"}
          </p>
        </div>
      )}
    </div>
  );
}




export default Dashboard;
